/* eslint-disable max-len */
import service from 'services/itemService';
import { v4 } from 'uuid';
import { failureRequest, finishRequest, startRequest } from './pageActions';

export const FILTER_ORDER_ITEM_REQUEST = '@order-item/fetch-all-order-item-request';
export const FILTER_ORDER_ITEM_SUCCESS = '@order-item/fetch-all-order-item-success';
export const FILTER_ORDER_ITEM_FAILURE = '@order-item/fetch-all-order-item-failure';

export const RESET_FILTER_ORDER_ITEM_REQUEST = '@order-item/reset-fetch-all-order-item-request';

export const FETCH_DETAIL_ORDER_ITEM_SUCCESS = '@order-item/fetch-detail-order-item-success';

export const ADD_SELECTED_ORDER_ITEM_SUCCESS = '@order-item/add-selected-detail-order-item-success';
export const REMOVE_SELECTED_ORDER_ITEM_SUCCESS = '@order-item/remove-selected-detail-order-item-success';

export const CHANGE_NAME_ORDER_DETAIL_REQUEST = '@order-item/change-name-order-item-request';
export const CHANGE_NAME_ORDER_DETAIL_SUCCESS = '@order-item/change-name-order-item-success';
export const CHANGE_NAME_ORDER_DETAIL_FAILURE = '@order-item/change-name-order-item-failure';

export const TRANSFER_ORDER_DETAIL_REQUEST = '@order-item/transfer-order-item-request';
export const TRANSFER_ORDER_DETAIL_SUCCESS = '@order-item/transfer-order-item-success';
export const TRANSFER_ORDER_DETAIL_FAILURE = '@order-item/transfer-order-item-failure';

export const MERGE_ORDER_ITEM_REQUEST = '@order-item/merge-order-item-request';
export const MERGE_ORDER_ITEM_SUCCESS = '@order-item/merge-order-item-success';
export const MERGE_ORDER_ITEM_FAILURE = '@order-item/merge-order-item-failure';

export const MERGE_SINGLE_ORDER_ITEM_REQUEST = '@order-item/merge-single-order-item-request';
export const MERGE_SINGLE_ORDER_ITEM_SUCCESS = '@order-item/merge-single-order-item-success';
export const MERGE_SINGLE_ORDER_ITEM_FAILURE = '@order-item/merge-single-order-item-failure';

export const UNMERGE_ORDER_ITEM_REQUEST = '@order-item/unmerge-order-item-request';
export const UNMERGE_ORDER_ITEM_SUCCESS = '@order-item/unmerge-order-item-success';
export const UNMERGE_ORDER_ITEM_FAILURE = '@order-item/unmerge-order-item-failure';

export const CUTED_ORDER_ITEM_REQUEST = '@order-item/cuted-order-item-request';
export const CUTED_ORDER_ITEM_SUCCESS = '@order-item/cuted-order-item-success';
export const CUTED_ORDER_ITEM_FAILURE = '@order-item/cuted-order-item-failure';

export const FILTER_MERGE_ORDER_ITEM_REQUEST = '@order-item/fetch-merge-order-item-request';
export const FILTER_MERGE_ORDER_ITEM_SUCCESS = '@order-item/fetch-merge-order-item-success';
export const FILTER_MERGE_ORDER_ITEM_FAILURE = '@order-item/fetch-merge-order-item-failure';

export const FETCH_DETAIL_MERGE_ORDER_ITEM_SUCCESS = '@order-item/fetch-detail-merge-order-item-success';

export function fetchListItem(page = 1, filters = {}, perPage = 10) {
  return async (dispatch) => {
    try {
      dispatch(startRequest());

      dispatch({ type: FILTER_ORDER_ITEM_REQUEST, payload: { filters } });

      const { data, meta } = await service.fetchListItem(page, filters, perPage);

      const items = [...data].map((item) => {
        const names = [...item.names].map((value) => ({ name: value, key: v4() }));

        return { ...item, names };
      });

      dispatch({ type: FILTER_ORDER_ITEM_SUCCESS, payload: { items, meta, filters } });

      dispatch(finishRequest());
    } catch (error) {
      dispatch({ type: FILTER_ORDER_ITEM_FAILURE, payload: { filters } });

      dispatch(failureRequest());

      throw error;
    }
  };
}

export function fetchDetailItem(item) {
  return (dispatch) => {
    dispatch(startRequest());

    dispatch({ type: FETCH_DETAIL_ORDER_ITEM_SUCCESS, payload: { item } });

    dispatch(finishRequest());
  };
}

export function addName(newName) {
  return async (dispatch, getState) => {
    try {
      dispatch(startRequest());

      dispatch({ type: CHANGE_NAME_ORDER_DETAIL_REQUEST });

      const { item, items } = getState().orderItem;
      const newNames = [...item.names, newName];

      await service.updateNames(item.id, [...newNames].map((value) => value.name));

      const newItems = [...items].map((value) => ({
        ...value,
        names: value.id === item.id ? newNames : value.names
      }));

      dispatch({
        type: CHANGE_NAME_ORDER_DETAIL_SUCCESS,
        payload: {
          items: newItems,
          item: { ...item, names: newNames }
        }
      });

      dispatch(finishRequest());
    } catch (error) {
      dispatch({ type: CHANGE_NAME_ORDER_DETAIL_FAILURE });

      dispatch(failureRequest());

      throw error;
    }
  };
}

export function changeName(newName) {
  return async (dispatch, getState) => {
    try {
      dispatch(startRequest());

      dispatch({ type: CHANGE_NAME_ORDER_DETAIL_REQUEST });

      const { item, items } = getState().orderItem;

      const newNames = [...item.names].map((value) => ({
        ...value,
        name: newName.key === value.key ? newName.name : value.name
      }));

      await service.updateNames(
        item.id,
        [...newNames].map((value) => value.name)
      );

      const newItems = [...items].map((value) => ({
        ...value,
        names: value.id === item.id ? newNames : value.names
      }));

      dispatch({
        type: CHANGE_NAME_ORDER_DETAIL_SUCCESS,
        payload: {
          items: newItems,
          item: { ...item, names: newNames }
        }
      });

      dispatch(finishRequest());
    } catch (error) {
      dispatch({ type: CHANGE_NAME_ORDER_DETAIL_FAILURE });

      dispatch(failureRequest());

      throw error;
    }
  };
}

export function resetFilterListItem(perPage = 10) {
  return async (dispatch) => {
    dispatch({ type: RESET_FILTER_ORDER_ITEM_REQUEST });

    await dispatch(fetchListItem(1, {}, perPage));
  };
}

export function addSelectedMergeItem(item) {
  return (dispatch, getState) => {
    const { selectedItems } = getState().orderItem;
    const quantity = selectedItems.reduce((accumulator, detail) => accumulator + detail.quantity, 0);
    const newQuantity = item.quantity + quantity;

    if (newQuantity > 7) {
      return;
    }

    const newSelectedItems = [...selectedItems];

    newSelectedItems.push(item);

    dispatch({ type: ADD_SELECTED_ORDER_ITEM_SUCCESS, payload: { selectedItems: newSelectedItems } });
  };
}

export function removeSelectedMergeItem(item) {
  return (dispatch, getState) => {
    const { selectedItems } = getState().orderItem;

    const newSelectedItems = [...selectedItems].filter((value) => value.id !== item.id);

    dispatch({ type: REMOVE_SELECTED_ORDER_ITEM_SUCCESS, payload: { selectedItems: newSelectedItems } });
  };
}

export function fetchListItemForMerge(page = 1, params: {}) {
  return async (dispatch, getState) => {
    const { user } = getState().account;

    const newParams = {
      ...params,
      mergeable: true,
      status: 'CREATED',
      branch: user.employee.branch.id
    };

    await dispatch(fetchListItem(page, newParams, 15));
  };
}

export function mergeItems(params) {
  return async (dispatch) => {
    try {
      dispatch(startRequest());

      dispatch({ type: MERGE_ORDER_ITEM_REQUEST });

      await service.mergeItems(params);

      dispatch({ type: MERGE_ORDER_ITEM_SUCCESS });

      dispatch(fetchListItemForMerge());
    } catch (error) {
      dispatch({ type: MERGE_ORDER_ITEM_FAILURE });

      dispatch(failureRequest());

      throw error;
    }
  };
}

export function mergeSingleItem(params) {
  return async (dispatch, getState) => {
    try {
      dispatch(startRequest());

      dispatch({ type: MERGE_SINGLE_ORDER_ITEM_REQUEST });

      await service.mergeItems(params);

      const { item, items } = getState().orderItem;

      dispatch({
        type: MERGE_SINGLE_ORDER_ITEM_SUCCESS,
        payload: {
          item: { ...item, status: 'MERGED' },
          items: [...items].map((value) => ({
            ...value,
            status: value.id === item.id ? 'MERGED' : value.status
          }))
        }
      });

      dispatch(finishRequest());
    } catch (error) {
      dispatch({ type: MERGE_SINGLE_ORDER_ITEM_FAILURE });

      dispatch(failureRequest());

      throw error;
    }
  };
}

export function fetchListMergeItems(page = 1, filters = {}, perPage = 10) {
  return async (dispatch) => {
    try {
      dispatch(startRequest());

      dispatch({ type: FILTER_MERGE_ORDER_ITEM_REQUEST, payload: { filters } });

      const { data: merges, meta } = await service.fetchListMergeItems(page, filters, perPage);

      dispatch({ type: FILTER_MERGE_ORDER_ITEM_SUCCESS, payload: { merges, meta, filters } });

      dispatch(finishRequest());
    } catch (error) {
      dispatch({ type: FILTER_MERGE_ORDER_ITEM_FAILURE, payload: { filters } });

      dispatch(failureRequest());

      throw error;
    }
  };
}

export function fetchMergeItem(item) {
  return async (dispatch) => {
    try {
      dispatch(startRequest());

      const merge = await service.fetchDetailMergeItem(item.id);

      dispatch({ type: FETCH_DETAIL_MERGE_ORDER_ITEM_SUCCESS, payload: { merge } });

      dispatch(finishRequest());
    } catch (error) {
      dispatch(failureRequest());

      throw error;
    }
  };
}

export function cancelMergeItem(item) {
  return async (dispatch, getState) => {
    try {
      const { merges, merge } = getState().merge;

      dispatch({ type: UNMERGE_ORDER_ITEM_REQUEST });

      dispatch(startRequest());

      await service.cancelMergeItem(item.id);

      const newMerges = [...merges].map((value) => {
        if (value.id === item.id) {
          return { ...value, status: 'CANCELED' };
        }

        return { ...value };
      });

      dispatch({
        type: UNMERGE_ORDER_ITEM_SUCCESS,
        payload: { merges: newMerges, merge: { ...merge, status: 'CANCELED' } }
      });

      dispatch(finishRequest());
    } catch (error) {
      dispatch({ type: UNMERGE_ORDER_ITEM_FAILURE });

      dispatch(failureRequest());

      throw error;
    }
  };
}

export function transferBranch({ id: itemId }, { id: branchId, name: branchName }) {
  return async (dispatch, getState) => {
    try {
      const branch = { id: branchId, name: branchName };
      const { item, items } = getState().orderItem;

      dispatch({ type: TRANSFER_ORDER_DETAIL_REQUEST });

      dispatch(startRequest());

      await service.transferBranch(itemId, branchId);

      const newItems = [...items].map((value) => {
        if (value.id === itemId) {
          return { ...value, branch };
        }

        return { ...value };
      });

      dispatch({
        type: TRANSFER_ORDER_DETAIL_SUCCESS,
        payload: { items: newItems, item: { ...item, branch } }
      });

      dispatch(finishRequest());
    } catch (error) {
      dispatch({ type: TRANSFER_ORDER_DETAIL_FAILURE });

      dispatch(failureRequest());

      throw error;
    }
  };
}

export function cutedMergeItem(merge, params) {
  return async (dispatch, getState) => {
    try {
      const { merges } = getState().merge;

      dispatch({ type: CUTED_ORDER_ITEM_REQUEST });

      dispatch(startRequest());

      await service.cutedMergeItem(merge.id, params);

      const newMerges = [...merges].map((value) => {
        if (value.id === merge.id) {
          return { ...value, status: 'CUTED' };
        }

        return { ...value };
      });

      dispatch({
        type: CUTED_ORDER_ITEM_SUCCESS,
        payload: { merges: newMerges, merge: { ...merge, status: 'CUTED' } }
      });

      dispatch(finishRequest());
    } catch (error) {
      dispatch({ type: CUTED_ORDER_ITEM_FAILURE });

      dispatch(failureRequest());

      throw error;
    }
  };
}
