/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  FETCH_ALL_BRANCH_REQUEST,
  FETCH_ALL_BRANCH_SUCCESS,
  FETCH_ALL_BRANCH_FAILURE
} from 'actions/branchActions';

const initialState = {
  branches: [],
  meta: {},
  filters: {},
  status: null
};

const branchReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_BRANCH_REQUEST: {
      return produce(state, (draft) => {
        draft.status = FETCH_ALL_BRANCH_REQUEST;
      });
    }

    case FETCH_ALL_BRANCH_SUCCESS: {
      const { branches } = action.payload;

      return produce(state, (draft) => {
        draft.branches = branches;
        draft.status = FETCH_ALL_BRANCH_SUCCESS;
      });
    }

    case FETCH_ALL_BRANCH_FAILURE: {
      return produce(state, (draft) => {
        draft.status = FETCH_ALL_BRANCH_FAILURE;
      });
    }

    default: {
      return state;
    }
  }
};

export default branchReducer;
