import axios from 'utils/axios';
import filterFromObject from 'utils/filterFromObject';

class RegionService {
  fetchAll = (filters) => new Promise((resolve, reject) => {
    const filterParam = filterFromObject(filters);

    axios.get(`/region?page=1&limit=100&${filterParam}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  })
}

const regionService = new RegionService();

export default regionService;
