import { API_BASE_URL } from 'config';
import axios from 'utils/axios';
import filterFromObject from 'utils/filterFromObject';

class DistributionService {
  fetchListDistributions = (page = 1, filters = {}) =>
    new Promise((resolve, reject) => {
      const filterParam = filterFromObject(filters);

      axios
        .get(`/distribution?page=${page}&limit=10&${filterParam}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });

  fetchItems = (page = 1, filters = {}) =>
    new Promise((resolve, reject) => {
      const filterParam = filterFromObject(filters);

      axios
        .get(`/distribution/item?page=${page}&limit=10&${filterParam}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });

  create = params =>
    new Promise((resolve, reject) => {
      axios
        .post('/distribution', params)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });

  update = (distributionId, params) =>
    new Promise((resolve, reject) => {
      axios
        .patch(`/distribution/${distributionId}`, params)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });

  updateAndSend = (distributionId, params) =>
    new Promise((resolve, reject) => {
      axios
        .post(`/distribution/${distributionId}/generate/warrant`, params)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });

  resend = distributionId =>
    new Promise((resolve, reject) => {
      axios
        .post(`/distribution/${distributionId}/resend/warrant`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });

  uploadFixedSpFile = (distributionId, file) =>
    new Promise((resolve, reject) => {
      const form = new FormData();

      form.append('file', file);

      axios
        .post(`/distribution/${distributionId}/upload/name`, form, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });

  cancel = distributionId =>
    new Promise((resolve, reject) => {
      axios
        .put(`/distribution/${distributionId}/cancel`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });

  addItem = (distributionId, combineId) =>
    new Promise((resolve, reject) => {
      axios
        .post('/distribution/item', { distributionId, combineId })
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });

  removeItem = itemId =>
    new Promise((resolve, reject) => {
      axios
        .delete(`/distribution/item/${itemId}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });

  downloadWarrant = ({ id, identificationNumber }) =>
    new Promise((resolve, reject) => {
      axios
        .get(`/distribution/${id}/download/warrant`, { responseType: 'blob' })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `SPQ-${identificationNumber}.xlsx`);
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => reject(error));
    });

  warrantDetail = (identificationNumber) =>
    new Promise((resolve, reject) => {
      axios
        .get(`${API_BASE_URL}/public/warrant/${identificationNumber}`)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });

  downloadNames = ({ id, identificationNumber }) =>
    new Promise((resolve, reject) => {
      axios
        .get(`${API_BASE_URL}/public/${id}/download/name`, { responseType: 'blob' })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `PLANG-NAMA-${identificationNumber}.docx`
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => reject(error));
    });

  printNames = (id) =>
    new Promise((resolve, reject) => {
      axios
        .get(`${API_BASE_URL}/public/${id}/print/name`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => reject(error));
    });
}

const distributionService = new DistributionService();

export default distributionService;
