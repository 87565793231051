/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
const filterFromObject = (filters) => {
  let filterParam = '';

  if (filters instanceof Object) {
    for (const property in filters) {
      filterParam += `&filter[${property}]=${filters[property] || ''}`;
    }
  }

  return filterParam;
};

export default filterFromObject;
