/* eslint-disable no-restricted-syntax */
/* eslint-disable func-names */

const isFormData = function (o) {
  return o instanceof FormData;
};

const isBlob = function (o) {
  return o instanceof Blob;
};

const isArray = function (a) {
  return Array.isArray(a);
};

const isObject = function (o) {
  return o === Object(o) && !isArray(o) && typeof o !== 'function';
};

const toCamel = (s) => s.replace(/([-_][a-z])/ig, ($1) => $1.toUpperCase()
  .replace('-', '')
  .replace('_', ''));

const toUnderscore = (s) => s.replace(/\.?([A-Z])/g, (x, y) => `_${y.toLowerCase()}`).replace(/^_/, '');

const keysToCamel = function (o) {
  if (isBlob(o)) {
    return o;
  } if (isObject(o)) {
    const n = {};

    Object.keys(o)
      .forEach((k) => {
        n[toCamel(k)] = keysToCamel(o[k]);
      });

    return n;
  } if (isArray(o)) {
    return o.map((i) => keysToCamel(i));
  }

  return o;
};

const keysToUnderscore = function (o) {
  if (isFormData(o)) {
    const formData = new FormData();

    for (const key of o.keys()) {
      formData.append(toUnderscore(key), o.get(key));
    }

    return formData;
  } if (isObject(o)) {
    const n = {};

    Object.keys(o)
      .forEach((k) => {
        n[toUnderscore(k)] = keysToUnderscore(o[k]);
      });

    return n;
  } if (isArray(o)) {
    return o.map((i) => keysToUnderscore(i));
  }

  return o;
};

const humps = {
  camelizeKeys: keysToCamel,
  decamelizeKeys: keysToUnderscore
};

export default humps;
