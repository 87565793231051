import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import moment from 'moment';
import MomentUtils from '@date-io/moment';

import { SnackbarProvider } from 'notistack';
import {
  createStyles,
  makeStyles,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Auth from 'components/Auth';
import ScrollReset from 'components/ScrollReset';
import useSettings from 'hooks/useSettings';
import { createTheme } from 'theme';
import Routes from 'Routes';

import 'moment/locale/id';

moment.locale('id');
const history = createBrowserHistory();

const useStyles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      height: '100%',
      width: '100%'
    },
    '#root': {
      height: '100%',
      width: '100%'
    }
  }
}));

function App() {
  useStyles();

  const { settings } = useSettings();
  const theme = createTheme(settings);

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider>
        <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale="id">
          <SnackbarProvider
            hideIconVariant
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            maxSnack={1}
          >
            <Router history={history}>
              <Auth>
                <ScrollReset />
                <Routes />
              </Auth>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
}

export default App;
