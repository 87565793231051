/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import LocalDiningOutlinedIcon from '@material-ui/icons/LocalDiningOutlined';
import LibraryAddOutlinedIcon from '@material-ui/icons/LibraryAddOutlined';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import DvrOutlinedIcon from '@material-ui/icons/DvrOutlined';
import AssignmentLateOutlinedIcon from '@material-ui/icons/AssignmentLateOutlined';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import {
  PieChart as PieChartIcon,
  ShoppingBag as ShoppingBagIcon,
  Gitlab as AnimalIcon
} from 'react-feather';
import Logo from 'components/Logo';
import NavItem from './NavItem';

const navConfig = [
  {
    subheader: 'Main',
    items: [
      {
        title: 'Dashboard',
        icon: PieChartIcon,
        href: '/'
      },
      {
        title: 'Transaksi',
        icon: ShoppingBagIcon,
        href: '/donors'
      },
      {
        title: 'Penggabungan',
        icon: LibraryAddOutlinedIcon,
        href: '/orders/merge/create'
      },
      {
        title: 'Surat Perintah Qurban',
        icon: AssignmentLateOutlinedIcon,
        href: '/distributions/create'
      },
      {
        title: 'Pemotongan',
        icon: LocalDiningOutlinedIcon,
        href: '/distributions/cutted'
      },
      {
        title: 'Buat Laporan',
        icon: NoteAddIcon,
        href: '/orders/report/create-new-version'
      },
      {
        title: 'Buat Laporan (lama)',
        icon: NoteAddIcon,
        href: '/orders/report/create'
      },
      {
        title: 'Upload Laporan',
        icon: AssignmentTurnedInOutlinedIcon,
        href: '/orders/report/upload'
      }
    ]
  },
  {
    subheader: 'Orders',
    items: [
      {
        title: 'List Pemesanan',
        icon: ReceiptIcon,
        href: '/orders/detail'
      },
      {
        title: 'List Hewan',
        icon: AnimalIcon,
        href: '/orders/merge'
      },

      {
        title: 'List Surat Perintah',
        icon: AssignmentLateOutlinedIcon,
        href: '/distributions'
      },
      {
        title: 'List Laporan',
        icon: AssignmentTurnedInOutlinedIcon,
        href: '/orders/report'
      },
      {
        title: 'List Transaksi',
        icon: DvrOutlinedIcon,
        href: '/orders'
      }
    ]
  }
];

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>{items.reduce((acc, item) => reduceChildRoutes({ acc, item, ...rest }), [])}</List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useSelector((state) => state.account);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <Avatar alt="User" className={classes.avatar} src="/static/images/avatars/avatar_6.png" />
          </Box>
          <Box mt={2} textAlign="center">
            <Link component="span" variant="h5" color="textPrimary" underline="none">
              {user.name}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {user.email}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {navConfig.map((config) => (
            <List
              key={config.subheader}
              subheader={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <ListSubheader disableGutters disableSticky>
                  {config.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({ items: config.items, pathname: location.pathname })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
