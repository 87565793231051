import axios from 'utils/axios';

class PartnerService {
  fetchAll = () => new Promise((resolve, reject) => {
    axios.get('/partner?page=1&limit=1000')
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  })
}

const partnerService = new PartnerService();

export default partnerService;
