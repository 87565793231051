export const PAGE_REQUEST_START = '@page/page-request-start';
export const PAGE_REQUEST_FINISH = '@page/page-request-finish';
export const PAGE_REQUEST_FAILURE = '@page/page-request-failure';

export function startRequest() {
  return (dispatch) => {
    dispatch({ type: PAGE_REQUEST_START });
  };
}

export function finishRequest(message = null) {
  return (dispatch) => {
    dispatch({ type: PAGE_REQUEST_FINISH, payload: { message } });
  };
}

export function failureRequest(message = null) {
  return (dispatch) => {
    dispatch({ type: PAGE_REQUEST_FAILURE, payload: { message } });
  };
}
