import donorService from 'services/donorService';
import { startRequest, finishRequest, failureRequest } from './pageActions';

export const FILTER_DONOR_REQUEST = '@donor/filter-donor-request';
export const FILTER_DONOR_SUCCESS = '@donor/filter-donor-success';
export const FILTER_DONOR_FAILURE = '@donor/filter-donor-failure';

export function fetchDonor(page = 1, filters = {}) {
  return async (dispatch) => {
    try {
      dispatch(startRequest());

      dispatch({ type: FILTER_DONOR_REQUEST, payload: { filters } });

      const { data: donors, meta } = await donorService.fetchListDonor(page, filters);

      dispatch({ type: FILTER_DONOR_SUCCESS, payload: { donors, meta } });

      dispatch(finishRequest());
    } catch (error) {
      dispatch({ type: FILTER_DONOR_FAILURE, payload: { filters } });

      dispatch(failureRequest());

      throw error;
    }
  };
}
