import service from 'services/regionService';

export const FETCH_ALL_REGION_REQUEST = '@region/fetch-all-region-request';
export const FETCH_ALL_REGION_SUCCESS = '@region/fetch-all-region-success';
export const FETCH_ALL_REGION_FAILURE = '@region/fetch-all-region-failure';

export function fetchAll() {
  return async (dispatch, getState) => {
    try {
      const { user } = getState().account;
      const { branch } = { ...user.employee };

      dispatch({ type: FETCH_ALL_REGION_REQUEST });

      const { data: regions } = await service.fetchAll({ branch: branch.isHeadOffice ? '' : branch.id });

      dispatch({ type: FETCH_ALL_REGION_SUCCESS, payload: { regions } });
    } catch (error) {
      dispatch({ type: FETCH_ALL_REGION_FAILURE });
      throw error;
    }
  };
}
