/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  FILTER_ORDER_ITEM_REQUEST,
  FILTER_ORDER_ITEM_SUCCESS,
  FILTER_ORDER_ITEM_FAILURE,
  FETCH_DETAIL_ORDER_ITEM_SUCCESS,
  CHANGE_NAME_ORDER_DETAIL_SUCCESS,
  CHANGE_NAME_ORDER_DETAIL_FAILURE,
  CHANGE_NAME_ORDER_DETAIL_REQUEST,
  RESET_FILTER_ORDER_ITEM_REQUEST,
  ADD_SELECTED_ORDER_ITEM_SUCCESS,
  REMOVE_SELECTED_ORDER_ITEM_SUCCESS,
  MERGE_ORDER_ITEM_REQUEST,
  MERGE_ORDER_ITEM_SUCCESS,
  MERGE_ORDER_ITEM_FAILURE,
  TRANSFER_ORDER_DETAIL_REQUEST,
  TRANSFER_ORDER_DETAIL_SUCCESS,
  TRANSFER_ORDER_DETAIL_FAILURE,
  MERGE_SINGLE_ORDER_ITEM_REQUEST,
  MERGE_SINGLE_ORDER_ITEM_FAILURE,
  MERGE_SINGLE_ORDER_ITEM_SUCCESS
} from 'actions/itemActions';
import { CANCEL_ORDER_SUCCESS } from 'actions/orderActions';

const initialState = {
  selectedItems: [],
  item: {},
  items: [],
  meta: {},
  filters: {},
  status: null
};

const orderItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case FILTER_ORDER_ITEM_REQUEST: {
      const { filters } = action.payload;

      return produce(state, (draft) => {
        draft.filters = filters;
        draft.status = FILTER_ORDER_ITEM_REQUEST;
      });
    }

    case FILTER_ORDER_ITEM_SUCCESS: {
      const { items, meta, filters } = action.payload;

      return produce(state, (draft) => {
        draft.items = items;
        draft.meta = meta;
        draft.filters = filters;
        draft.status = FILTER_ORDER_ITEM_SUCCESS;
      });
    }

    case FILTER_ORDER_ITEM_FAILURE: {
      const { filters } = action.payload;

      return produce(state, (draft) => {
        draft.filters = filters;
        draft.status = FILTER_ORDER_ITEM_FAILURE;
      });
    }

    case FETCH_DETAIL_ORDER_ITEM_SUCCESS: {
      const { item } = action.payload;

      return produce(state, (draft) => {
        draft.item = item;
        draft.status = FETCH_DETAIL_ORDER_ITEM_SUCCESS;
      });
    }

    case CHANGE_NAME_ORDER_DETAIL_REQUEST: {
      return produce(state, (draft) => {
        draft.status = CHANGE_NAME_ORDER_DETAIL_REQUEST;
      });
    }

    case CHANGE_NAME_ORDER_DETAIL_SUCCESS: {
      const { item, items } = action.payload;

      return produce(state, (draft) => {
        draft.item = item;
        draft.items = items;
        draft.status = CHANGE_NAME_ORDER_DETAIL_SUCCESS;
      });
    }

    case CHANGE_NAME_ORDER_DETAIL_FAILURE: {
      return produce(state, (draft) => {
        draft.status = CHANGE_NAME_ORDER_DETAIL_FAILURE;
      });
    }

    case CANCEL_ORDER_SUCCESS: {
      const { orderId } = action.payload;

      return produce(state, (draft) => {
        draft.item = { ...state.item, status: 'CANCELED' };
        draft.items = [...state.items].map((value) => ({ ...value, status: value.orderId === orderId ? 'CANCELED' : value.status }));
        draft.status = CANCEL_ORDER_SUCCESS;
      });
    }

    case RESET_FILTER_ORDER_ITEM_REQUEST: {
      return produce(state, (draft) => {
        draft.status = RESET_FILTER_ORDER_ITEM_REQUEST;
      });
    }

    case ADD_SELECTED_ORDER_ITEM_SUCCESS: {
      const { selectedItems } = action.payload;

      return produce(state, (draft) => {
        draft.selectedItems = selectedItems;
        draft.status = ADD_SELECTED_ORDER_ITEM_SUCCESS;
      });
    }

    case REMOVE_SELECTED_ORDER_ITEM_SUCCESS: {
      const { selectedItems } = action.payload;

      return produce(state, (draft) => {
        draft.selectedItems = selectedItems;
        draft.status = REMOVE_SELECTED_ORDER_ITEM_SUCCESS;
      });
    }

    case MERGE_ORDER_ITEM_REQUEST: {
      return produce(state, (draft) => {
        draft.status = MERGE_ORDER_ITEM_REQUEST;
      });
    }

    case MERGE_ORDER_ITEM_SUCCESS: {
      return produce(state, (draft) => {
        draft.selectedItems = [];
        draft.status = MERGE_ORDER_ITEM_SUCCESS;
      });
    }

    case MERGE_ORDER_ITEM_FAILURE: {
      return produce(state, (draft) => {
        draft.status = MERGE_ORDER_ITEM_FAILURE;
      });
    }

    case TRANSFER_ORDER_DETAIL_REQUEST: {
      return produce(state, (draft) => {
        draft.status = TRANSFER_ORDER_DETAIL_REQUEST;
      });
    }

    case TRANSFER_ORDER_DETAIL_SUCCESS: {
      const { items, item } = action.payload;

      return produce(state, (draft) => {
        draft.item = item;
        draft.items = items;
        draft.status = TRANSFER_ORDER_DETAIL_SUCCESS;
      });
    }

    case TRANSFER_ORDER_DETAIL_FAILURE: {
      return produce(state, (draft) => {
        draft.status = TRANSFER_ORDER_DETAIL_FAILURE;
      });
    }

    case MERGE_SINGLE_ORDER_ITEM_REQUEST: {
      return produce(state, (draft) => {
        draft.status = MERGE_SINGLE_ORDER_ITEM_REQUEST;
      });
    }

    case MERGE_SINGLE_ORDER_ITEM_SUCCESS: {
      const { item, items } = action.payload;

      return produce(state, (draft) => {
        draft.item = item;
        draft.items = items;
        draft.status = MERGE_SINGLE_ORDER_ITEM_SUCCESS;
      });
    }

    case MERGE_SINGLE_ORDER_ITEM_FAILURE: {
      return produce(state, (draft) => {
        draft.status = MERGE_SINGLE_ORDER_ITEM_FAILURE;
      });
    }

    default: {
      return state;
    }
  }
};

export default orderItemReducer;
