/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  FILTER_MERGE_ORDER_ITEM_REQUEST,
  FILTER_MERGE_ORDER_ITEM_SUCCESS,
  FILTER_MERGE_ORDER_ITEM_FAILURE,
  FETCH_DETAIL_MERGE_ORDER_ITEM_SUCCESS,
  UNMERGE_ORDER_ITEM_REQUEST,
  UNMERGE_ORDER_ITEM_FAILURE,
  UNMERGE_ORDER_ITEM_SUCCESS,
  CUTED_ORDER_ITEM_FAILURE,
  CUTED_ORDER_ITEM_REQUEST,
  CUTED_ORDER_ITEM_SUCCESS
} from 'actions/itemActions';
import { FETCH_ITEMS_DISTRIBUTION_REQUEST } from 'actions/distributionActions';

const initialState = {
  merge: {},
  merges: [],
  meta: {},
  filters: {},
  status: null
};

const mergeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FILTER_MERGE_ORDER_ITEM_REQUEST: {
      return produce(state, (draft) => {
        draft.merge = {};
        draft.status = FILTER_MERGE_ORDER_ITEM_REQUEST;
      });
    }

    case FILTER_MERGE_ORDER_ITEM_SUCCESS: {
      const { merges, meta, filters } = action.payload;

      return produce(state, (draft) => {
        draft.meta = meta;
        draft.merges = merges;
        draft.filters = filters;
        draft.status = FILTER_MERGE_ORDER_ITEM_SUCCESS;
      });
    }

    case FILTER_MERGE_ORDER_ITEM_FAILURE: {
      return produce(state, (draft) => {
        draft.status = FILTER_MERGE_ORDER_ITEM_FAILURE;
      });
    }

    case UNMERGE_ORDER_ITEM_REQUEST: {
      return produce(state, (draft) => {
        draft.status = UNMERGE_ORDER_ITEM_REQUEST;
      });
    }

    case UNMERGE_ORDER_ITEM_FAILURE: {
      return produce(state, (draft) => {
        draft.status = UNMERGE_ORDER_ITEM_FAILURE;
      });
    }

    case UNMERGE_ORDER_ITEM_SUCCESS: {
      const { merge, merges } = action.payload;

      return produce(state, (draft) => {
        draft.merge = merge;
        draft.merges = merges;
        draft.status = UNMERGE_ORDER_ITEM_SUCCESS;
      });
    }

    case FETCH_DETAIL_MERGE_ORDER_ITEM_SUCCESS: {
      const { merge } = action.payload;

      return produce(state, (draft) => {
        draft.merge = merge;
        draft.status = FETCH_DETAIL_MERGE_ORDER_ITEM_SUCCESS;
      });
    }

    case CUTED_ORDER_ITEM_REQUEST: {
      return produce(state, (draft) => {
        draft.status = CUTED_ORDER_ITEM_REQUEST;
      });
    }

    case CUTED_ORDER_ITEM_FAILURE: {
      return produce(state, (draft) => {
        draft.status = CUTED_ORDER_ITEM_FAILURE;
      });
    }

    case CUTED_ORDER_ITEM_SUCCESS: {
      const { merge, merges } = action.payload;

      return produce(state, (draft) => {
        draft.merge = merge;
        draft.merges = merges;
        draft.status = CUTED_ORDER_ITEM_SUCCESS;
      });
    }

    case FETCH_ITEMS_DISTRIBUTION_REQUEST: {
      return produce(state, (draft) => {
        draft.merge = {};
        draft.merges = [];
      });
    }

    default: {
      return state;
    }
  }
};

export default mergeReducer;
