/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  FILTER_DONOR_FAILURE,
  FILTER_DONOR_SUCCESS,
  FILTER_DONOR_REQUEST
} from 'actions/donorActions';

const initialState = {
  donors: [],
  meta: {},
  filters: {},
  status: null
};

const donorReducer = (state = initialState, action) => {
  switch (action.type) {
    case FILTER_DONOR_REQUEST: {
      const { filters } = action.payload;

      return produce(state, (draft) => {
        draft.filters = filters;
        draft.status = FILTER_DONOR_REQUEST;
      });
    }

    case FILTER_DONOR_SUCCESS: {
      const { donors, meta } = action.payload;

      return produce(state, (draft) => {
        draft.donors = donors;
        draft.meta = meta;
        draft.status = FILTER_DONOR_SUCCESS;
      });
    }

    case FILTER_DONOR_FAILURE: {
      const { filters } = action.payload;

      return produce(state, (draft) => {
        draft.filters = filters;
        draft.status = FILTER_DONOR_FAILURE;
      });
    }

    default: {
      return state;
    }
  }
};

export default donorReducer;
