/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  STORE_DRAFT_DISTRIBUTION_REQUEST,
  STORE_DRAFT_DISTRIBUTION_SUCCESS,
  STORE_DRAFT_DISTRIBUTION_FAILURE,
  UPDATE_DRAFT_DISTRIBUTION_REQUEST,
  UPDATE_DRAFT_DISTRIBUTION_SUCCESS,
  UPDATE_DRAFT_DISTRIBUTION_FAILURE,
  ADD_ITEM_DISTRIBUTION_REQUEST,
  ADD_ITEM_DISTRIBUTION_FAILURE,
  ADD_ITEM_DISTRIBUTION_SUCCESS,
  REMOVE_ITEM_DISTRIBUTION_REQUEST,
  REMOVE_ITEM_DISTRIBUTION_FAILURE,
  REMOVE_ITEM_DISTRIBUTION_SUCCESS,
  FETCH_ITEMS_DISTRIBUTION_REQUEST,
  FETCH_ITEMS_DISTRIBUTION_FAILURE,
  FETCH_ITEMS_DISTRIBUTION_SUCCESS,
  UPDATE_AND_SEND_DRAFT_DISTRIBUTION_SUCCESS,
  UPDATE_AND_SEND_DRAFT_DISTRIBUTION_REQUEST,
  UPDATE_AND_SEND_DRAFT_DISTRIBUTION_FAILURE,
  RESET_STATE_DISTRIBUTION,
  FETCH_DISTRIBUTION_LIST_SUCCESS,
  FETCH_DISTRIBUTION_LIST_REQUEST,
  FETCH_DISTRIBUTION_LIST_FAILURE,
  SHOW_DISTRIBUTION_DETAIL_SUCCESS,
  CANCEL_DRAFT_DISTRIBUTION_SUCCESS,
  CANCEL_DRAFT_DISTRIBUTION_REQUEST,
  CANCEL_DRAFT_DISTRIBUTION_FAILURE
} from 'actions/distributionActions';

const initialState = {
  distributions: [],
  meta: {},
  filters: {},
  distribution: {},
  items: [],
  metaItems: {},
  filterItems: {},
  status: null
};

const distributionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_DISTRIBUTION_DETAIL_SUCCESS: {
      const { distribution } = action.payload;

      return produce(state, (draft) => {
        draft.distribution = distribution;
        draft.status = SHOW_DISTRIBUTION_DETAIL_SUCCESS;
      });
    }

    case FETCH_DISTRIBUTION_LIST_SUCCESS: {
      const { distributions, meta, filters } = action.payload;

      return produce(state, (draft) => {
        draft.meta = meta;
        draft.filters = filters;
        draft.distributions = distributions;
        draft.status = FETCH_DISTRIBUTION_LIST_SUCCESS;
      });
    }

    case FETCH_DISTRIBUTION_LIST_REQUEST: {
      return produce(state, (draft) => {
        draft.status = FETCH_DISTRIBUTION_LIST_REQUEST;
      });
    }

    case FETCH_DISTRIBUTION_LIST_FAILURE: {
      return produce(state, (draft) => {
        draft.status = FETCH_DISTRIBUTION_LIST_FAILURE;
      });
    }

    case FETCH_ITEMS_DISTRIBUTION_SUCCESS: {
      const { items, metaItems } = action.payload;

      return produce(state, (draft) => {
        draft.items = items;
        draft.metaItems = metaItems;
        draft.status = FETCH_ITEMS_DISTRIBUTION_SUCCESS;
      });
    }

    case FETCH_ITEMS_DISTRIBUTION_REQUEST: {
      return produce(state, (draft) => {
        draft.items = [];
        draft.metaItems = {};
        draft.status = FETCH_ITEMS_DISTRIBUTION_REQUEST;
      });
    }

    case FETCH_ITEMS_DISTRIBUTION_FAILURE: {
      return produce(state, (draft) => {
        draft.status = FETCH_ITEMS_DISTRIBUTION_FAILURE;
      });
    }

    case STORE_DRAFT_DISTRIBUTION_SUCCESS: {
      const { distribution } = action.payload;

      return produce(state, (draft) => {
        draft.distribution = distribution;
        draft.status = STORE_DRAFT_DISTRIBUTION_SUCCESS;
      });
    }

    case STORE_DRAFT_DISTRIBUTION_REQUEST: {
      return produce(state, (draft) => {
        draft.status = STORE_DRAFT_DISTRIBUTION_REQUEST;
      });
    }

    case STORE_DRAFT_DISTRIBUTION_FAILURE: {
      return produce(state, (draft) => {
        draft.status = STORE_DRAFT_DISTRIBUTION_FAILURE;
      });
    }

    case UPDATE_DRAFT_DISTRIBUTION_SUCCESS: {
      const { distribution } = action.payload;

      return produce(state, (draft) => {
        draft.distribution = distribution;
        draft.status = UPDATE_DRAFT_DISTRIBUTION_SUCCESS;
      });
    }

    case UPDATE_DRAFT_DISTRIBUTION_REQUEST: {
      return produce(state, (draft) => {
        draft.status = UPDATE_DRAFT_DISTRIBUTION_REQUEST;
      });
    }

    case UPDATE_DRAFT_DISTRIBUTION_FAILURE: {
      return produce(state, (draft) => {
        draft.status = UPDATE_DRAFT_DISTRIBUTION_FAILURE;
      });
    }

    case UPDATE_AND_SEND_DRAFT_DISTRIBUTION_SUCCESS: {
      const { distribution } = action.payload;

      return produce(state, (draft) => {
        draft.distribution = distribution;
        draft.status = UPDATE_AND_SEND_DRAFT_DISTRIBUTION_SUCCESS;
      });
    }

    case UPDATE_AND_SEND_DRAFT_DISTRIBUTION_REQUEST: {
      return produce(state, (draft) => {
        draft.status = UPDATE_AND_SEND_DRAFT_DISTRIBUTION_REQUEST;
      });
    }

    case UPDATE_AND_SEND_DRAFT_DISTRIBUTION_FAILURE: {
      return produce(state, (draft) => {
        draft.status = UPDATE_AND_SEND_DRAFT_DISTRIBUTION_FAILURE;
      });
    }

    case ADD_ITEM_DISTRIBUTION_SUCCESS: {
      return produce(state, (draft) => {
        draft.status = ADD_ITEM_DISTRIBUTION_SUCCESS;
      });
    }

    case ADD_ITEM_DISTRIBUTION_REQUEST: {
      return produce(state, (draft) => {
        draft.status = ADD_ITEM_DISTRIBUTION_REQUEST;
      });
    }

    case ADD_ITEM_DISTRIBUTION_FAILURE: {
      return produce(state, (draft) => {
        draft.status = ADD_ITEM_DISTRIBUTION_FAILURE;
      });
    }

    case REMOVE_ITEM_DISTRIBUTION_SUCCESS: {
      return produce(state, (draft) => {
        draft.status = REMOVE_ITEM_DISTRIBUTION_SUCCESS;
      });
    }

    case REMOVE_ITEM_DISTRIBUTION_REQUEST: {
      return produce(state, (draft) => {
        draft.status = REMOVE_ITEM_DISTRIBUTION_REQUEST;
      });
    }

    case REMOVE_ITEM_DISTRIBUTION_FAILURE: {
      return produce(state, (draft) => {
        draft.status = REMOVE_ITEM_DISTRIBUTION_FAILURE;
      });
    }

    case CANCEL_DRAFT_DISTRIBUTION_SUCCESS: {
      const { distribution, distributions } = action.payload;

      return produce(state, (draft) => {
        draft.distributions = distributions;
        draft.distribution = distribution;
        draft.status = CANCEL_DRAFT_DISTRIBUTION_SUCCESS;
      });
    }

    case CANCEL_DRAFT_DISTRIBUTION_REQUEST: {
      return produce(state, (draft) => {
        draft.status = CANCEL_DRAFT_DISTRIBUTION_REQUEST;
      });
    }

    case CANCEL_DRAFT_DISTRIBUTION_FAILURE: {
      return produce(state, (draft) => {
        draft.status = CANCEL_DRAFT_DISTRIBUTION_FAILURE;
      });
    }

    case RESET_STATE_DISTRIBUTION: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export default distributionReducer;
