import branchService from 'services/branchService';

export const FETCH_ALL_BRANCH_REQUEST = '@branch/fetch-all-branch-request';
export const FETCH_ALL_BRANCH_SUCCESS = '@branch/fetch-all-branch-success';
export const FETCH_ALL_BRANCH_FAILURE = '@branch/fetch-all-branch-failure';

export function fetchAll() {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_ALL_BRANCH_REQUEST });

      const { data: branches } = await branchService.fetchAll();

      dispatch({ type: FETCH_ALL_BRANCH_SUCCESS, payload: { branches } });
    } catch (error) {
      dispatch({ type: FETCH_ALL_BRANCH_FAILURE });
      throw error;
    }
  };
}
