import service from 'services/orderService';
import { failureRequest, finishRequest, startRequest } from './pageActions';

export const FILTER_ORDER_REPORT_REQUEST = '@order-report/fetch-order-report-request';
export const FILTER_ORDER_REPORT_SUCCESS = '@order-report/fetch-order-report-success';
export const FILTER_ORDER_REPORT_FAILURE = '@order-report/fetch-order-report-failure';

export const SENDING_ORDER_REPORT_REQUEST = '@order-report/sending-order-report-request';
export const SENDING_ORDER_REPORT_SUCCESS = '@order-report/sending-order-report-success';
export const SENDING_ORDER_REPORT_FAILURE = '@order-report/sending-order-report-failure';

export function fetchListOrderReport(page = 1, filters = {}) {
  return async (dispatch) => {
    try {
      dispatch(startRequest());

      dispatch({ type: FILTER_ORDER_REPORT_REQUEST, payload: { filters } });

      const { data, meta } = await service.fetchListOrderReport(page, filters);

      dispatch({ type: FILTER_ORDER_REPORT_SUCCESS, payload: { reports: data, meta, filters } });

      dispatch(finishRequest());
    } catch (error) {
      dispatch({ type: FILTER_ORDER_REPORT_FAILURE, payload: { filters } });

      dispatch(failureRequest());

      throw error;
    }
  };
}

export function sendReport(report, distributionAt) {
  return async (dispatch, getState) => {
    try {
      const { reports } = getState().orderReport;

      dispatch(startRequest());

      dispatch({ type: SENDING_ORDER_REPORT_REQUEST });

      await service.sendingReport(report, distributionAt);

      const newReports = reports.map((item) =>
        item.id === report.id ? { ...item, sendingAt: new Date() } : { ...item }
      );

      dispatch({ type: SENDING_ORDER_REPORT_SUCCESS, payload: { reports: newReports } });

      dispatch(finishRequest());
    } catch (error) {
      dispatch({ type: SENDING_ORDER_REPORT_FAILURE });

      dispatch(failureRequest());

      throw error;
    }
  };
}
