/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import MainLayout from 'layouts/MainLayout';
import LoadingScreen from 'components/LoadingScreen';
import AuthGuard from 'components/AuthGuard';
import GuestGuard from 'components/GuestGuard';

const routesConfig = [
  {
    exact: true,
    path: '/error401',
    component: lazy(() => import('views/errors/Error401View'))
  },
  {
    exact: true,
    path: '/error404',
    component: lazy(() => import('views/errors/Error404View'))
  },
  {
    exact: true,
    path: '/error500',
    component: lazy(() => import('views/errors/Error500View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('views/auth/LoginView'))
  },
  {
    path: '/distributions/:identificationNumber/print',
    exact: true,
    component: lazy(() => import('views/pages/PrintDistributionView'))
  },
  {
    path: '*',
    guard: AuthGuard,
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(() => import('views/pages/DashboardView'))
      },
      {
        exact: true,
        path: '/donors',
        component: lazy(() => import('views/pages/ListDonorView'))
      },
      {
        exact: true,
        path: '/orders/detail',
        component: lazy(() => import('views/pages/ListOrderItemView'))
      },
      {
        exact: true,
        path: '/orders/merge/create',
        component: lazy(() => import('views/pages/MergeOrderItemView'))
      },
      {
        exact: true,
        path: '/orders/merge',
        component: lazy(() => import('views/pages/ListMergeView'))
      },
      {
        exact: true,
        path: '/orders/create',
        component: lazy(() => import('views/pages/CreateOrderView'))
      },
      {
        exact: true,
        path: '/orders',
        component: lazy(() => import('views/pages/ListOrderView'))
      },
      {
        exact: true,
        path: '/orders/report',
        component: lazy(() => import('views/pages/ListOrderReportView'))
      },
      {
        exact: true,
        path: '/distributions/create',
        component: lazy(() => import('views/pages/CreateDistributionView'))
      },
      {
        exact: true,
        path: '/distributions/cutted',
        component: lazy(() => import('views/pages/ListCutedView'))
      },
      {
        exact: true,
        path: '/distributions/cutted/report',
        component: lazy(() => import('views/pages/CreateReportCutted'))
      },
      {
        exact: true,
        path: '/orders/report/create',
        component: lazy(() => import('views/pages/CreateNewReportView'))
      },
      {
        exact: true,
        path: '/orders/report/create-new-version',
        component: lazy(() => import('views/pages/CreateNewReportVersionView'))
      },
      {
        exact: true,
        path: '/orders/report/upload',
        component: lazy(() => import('views/pages/UploadCutedImageView'))
      },
      {
        exact: true,
        path: '/distributions',
        component: lazy(() => import('views/pages/ListDistributionView'))
      },
      {
        component: () => <Redirect to="/error404" />
      }
    ]
  },
];

const renderRoutes = (routes) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
