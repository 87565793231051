/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  FETCH_ALL_REGION_REQUEST,
  FETCH_ALL_REGION_SUCCESS,
  FETCH_ALL_REGION_FAILURE
} from 'actions/regionActions';

const initialState = {
  regions: [],
  meta: {},
  filters: {},
  status: null
};

const regionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_REGION_REQUEST: {
      return produce(state, (draft) => {
        draft.status = FETCH_ALL_REGION_REQUEST;
      });
    }

    case FETCH_ALL_REGION_SUCCESS: {
      const { regions } = action.payload;

      return produce(state, (draft) => {
        draft.regions = regions;
        draft.status = FETCH_ALL_REGION_SUCCESS;
      });
    }

    case FETCH_ALL_REGION_FAILURE: {
      return produce(state, (draft) => {
        draft.status = FETCH_ALL_REGION_FAILURE;
      });
    }

    default: {
      return state;
    }
  }
};

export default regionReducer;
