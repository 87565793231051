import orderService from 'services/orderService';
import { startRequest, finishRequest, failureRequest } from './pageActions';

export const FILTER_ORDER_REQUEST = '@order/filter-order-request';
export const FILTER_ORDER_SUCCESS = '@order/filter-order-success';
export const FILTER_ORDER_FAILURE = '@order/filter-order-failure';

export const CREATE_ORDER_REQUEST = '@order/create-order-request';
export const CREATE_ORDER_SUCCESS = '@order/create-order-success';
export const CREATE_ORDER_FAILURE = '@order/create-order-failure';

export const CANCEL_ORDER_REQUEST = '@order/cancel-order-request';
export const CANCEL_ORDER_SUCCESS = '@order/cancel-order-success';
export const CANCEL_ORDER_FAILURE = '@order/cancel-order-failure';

export const UPLOAD_REPORT_ORDER_REQUEST = '@order/upload-report-order-request';
export const UPLOAD_REPORT_ORDER_SUCCESS = '@order/upload-report-order-success';
export const UPLOAD_REPORT_ORDER_FAILURE = '@order/upload-report-order-failure';

export const FETCH_ORDER_ITEMS_REQUEST = '@order/fetch-order-item-request';
export const FETCH_ORDER_ITEMS_SUCCESS = '@order/fetch-order-item-success';
export const FETCH_ORDER_ITEMS_FAILURE = '@order/fetch-order-item-failure';

export function fetchOrder(page = 1, filters = {}) {
  return async (dispatch) => {
    try {
      dispatch(startRequest());

      dispatch({ type: FILTER_ORDER_REQUEST, payload: { filters } });

      const { data: orders, meta } = await orderService.fetchListOrder(page, filters);

      dispatch({ type: FILTER_ORDER_SUCCESS, payload: { orders, meta } });

      dispatch(finishRequest());
    } catch (error) {
      dispatch({ type: FILTER_ORDER_FAILURE, payload: { filters } });

      dispatch(failureRequest());

      throw error;
    }
  };
}

export function fetchOrderItems(orderId) {
  return async (dispatch) => {
    try {
      dispatch(startRequest());

      dispatch({ type: FETCH_ORDER_ITEMS_REQUEST });

      const items = await orderService.fetchOrderItems(orderId);

      dispatch({ type: FETCH_ORDER_ITEMS_SUCCESS, payload: { items } });

      dispatch(finishRequest());
    } catch (error) {
      dispatch({ type: FETCH_ORDER_ITEMS_FAILURE });

      dispatch(failureRequest());

      throw error;
    }
  };
}

export function createOrder(order) {
  return async (dispatch) => {
    try {
      dispatch(startRequest());

      dispatch({ type: CREATE_ORDER_REQUEST });

      await orderService.createOrder(order);

      dispatch({ type: CREATE_ORDER_SUCCESS });

      dispatch(finishRequest());
    } catch (error) {
      dispatch({ type: CREATE_ORDER_FAILURE });

      dispatch(failureRequest());

      throw error;
    }
  };
}

export function cancelOrder(orderId) {
  return async (dispatch) => {
    try {
      dispatch(startRequest());

      dispatch({ type: CANCEL_ORDER_REQUEST });

      await orderService.cancelOrder(orderId);

      dispatch({ type: CANCEL_ORDER_SUCCESS, payload: { orderId } });

      dispatch(finishRequest());
    } catch (error) {
      dispatch({ type: CANCEL_ORDER_FAILURE });

      dispatch(failureRequest());

      throw error;
    }
  };
}

export function uploadReport(orderId, formData) {
  return async (dispatch) => {
    try {
      dispatch(startRequest());

      dispatch({ type: UPLOAD_REPORT_ORDER_REQUEST });

      await orderService.uploadReport(orderId, formData);

      dispatch({ type: UPLOAD_REPORT_ORDER_SUCCESS });

      dispatch(finishRequest());
    } catch (error) {
      dispatch({ type: UPLOAD_REPORT_ORDER_FAILURE });

      dispatch(failureRequest());

      throw error;
    }
  };
}
