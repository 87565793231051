import axios from 'utils/axios';

class EmployeeService {
  fetchAll = () => new Promise((resolve, reject) => {
    axios.get('/employee?page=1&limit=1000')
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  })
}

const employeeService = new EmployeeService();

export default employeeService;
