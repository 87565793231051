import service from 'services/distributionService';
import { failureRequest, finishRequest, startRequest } from './pageActions';

export const RESET_STATE_DISTRIBUTION = '@distribution/reset-state';

export const FETCH_DISTRIBUTION_LIST_REQUEST = '@distribution/fetch-distribution-list-request';
export const FETCH_DISTRIBUTION_LIST_SUCCESS = '@distribution/fetch-distribution-list-success';
export const FETCH_DISTRIBUTION_LIST_FAILURE = '@distribution/fetch-distribution-list-failure';

export const SHOW_DISTRIBUTION_DETAIL_SUCCESS = '@distribution/show-distribution-detail-success';

export const STORE_DRAFT_DISTRIBUTION_REQUEST = '@distribution/store-draft-distribution-request';
export const STORE_DRAFT_DISTRIBUTION_SUCCESS = '@distribution/store-draft-distribution-success';
export const STORE_DRAFT_DISTRIBUTION_FAILURE = '@distribution/store-draft-distribution-failure';

export const CANCEL_DRAFT_DISTRIBUTION_REQUEST = '@distribution/cancel-draft-distribution-request';
export const CANCEL_DRAFT_DISTRIBUTION_SUCCESS = '@distribution/cancel-draft-distribution-success';
export const CANCEL_DRAFT_DISTRIBUTION_FAILURE = '@distribution/cancel-draft-distribution-failure';

export const UPDATE_DRAFT_DISTRIBUTION_REQUEST = '@distribution/update-draft-distribution-request';
export const UPDATE_DRAFT_DISTRIBUTION_SUCCESS = '@distribution/update-draft-distribution-success';
export const UPDATE_DRAFT_DISTRIBUTION_FAILURE = '@distribution/update-draft-distribution-failure';

export const UPDATE_AND_SEND_DRAFT_DISTRIBUTION_REQUEST = '@distribution/update-draft-distribution-request';
export const UPDATE_AND_SEND_DRAFT_DISTRIBUTION_SUCCESS = '@distribution/update-draft-distribution-success';
export const UPDATE_AND_SEND_DRAFT_DISTRIBUTION_FAILURE = '@distribution/update-draft-distribution-failure';

export const FETCH_ITEMS_DISTRIBUTION_REQUEST = '@distribution/fetch-items-distribution-request';
export const FETCH_ITEMS_DISTRIBUTION_SUCCESS = '@distribution/fetch-items-distribution-success';
export const FETCH_ITEMS_DISTRIBUTION_FAILURE = '@distribution/fetch-items-distribution-failure';

export const ADD_ITEM_DISTRIBUTION_REQUEST = '@distribution/add-item-distribution-request';
export const ADD_ITEM_DISTRIBUTION_SUCCESS = '@distribution/add-item-distribution-success';
export const ADD_ITEM_DISTRIBUTION_FAILURE = '@distribution/add-item-distribution-failure';

export const REMOVE_ITEM_DISTRIBUTION_REQUEST = '@distribution/remove-item-distribution-request';
export const REMOVE_ITEM_DISTRIBUTION_SUCCESS = '@distribution/remove-item-distribution-success';
export const REMOVE_ITEM_DISTRIBUTION_FAILURE = '@distribution/remove-item-distribution-failure';

export function resetState() {
  return (dispatch) => {
    dispatch({ type: RESET_STATE_DISTRIBUTION });
  };
}

export function fetchListDistributions(page = 1, filters = {}) {
  return async (dispatch) => {
    try {
      dispatch(startRequest());

      dispatch({ type: FETCH_DISTRIBUTION_LIST_REQUEST });

      const { data: distributions, meta } = await service.fetchListDistributions(page, filters);

      // eslint-disable-next-line max-len
      dispatch({ type: FETCH_DISTRIBUTION_LIST_SUCCESS, payload: { distributions, meta, filters } });

      dispatch(finishRequest());
    } catch (error) {
      dispatch({ type: FETCH_DISTRIBUTION_LIST_FAILURE });

      dispatch(failureRequest());

      throw error;
    }
  };
}

export function fetchItems(page = 1, params = {}) {
  return async (dispatch) => {
    try {
      dispatch(startRequest());

      dispatch({ type: FETCH_ITEMS_DISTRIBUTION_REQUEST });

      const { data: items, meta: metaItems } = await service.fetchItems(page, params);

      dispatch({ type: FETCH_ITEMS_DISTRIBUTION_SUCCESS, payload: { items, metaItems } });

      dispatch(finishRequest());
    } catch (error) {
      dispatch({ type: FETCH_ITEMS_DISTRIBUTION_FAILURE });

      dispatch(failureRequest());

      throw error;
    }
  };
}

export function create(params) {
  return async (dispatch) => {
    try {
      dispatch(startRequest());

      dispatch({ type: STORE_DRAFT_DISTRIBUTION_REQUEST });

      const { distribution } = await service.create({ ...params, status: 'DRAFTED' });

      dispatch({ type: STORE_DRAFT_DISTRIBUTION_SUCCESS, payload: { distribution } });

      dispatch(finishRequest());
    } catch (error) {
      dispatch({ type: STORE_DRAFT_DISTRIBUTION_FAILURE });

      dispatch(failureRequest());

      throw error;
    }
  };
}

export function update(distribution, params) {
  return async (dispatch) => {
    try {
      dispatch(startRequest());

      dispatch({ type: UPDATE_DRAFT_DISTRIBUTION_REQUEST });

      const { id: distributionId } = distribution;
      await service.update(distributionId, params);

      dispatch({
        type: UPDATE_DRAFT_DISTRIBUTION_SUCCESS,
        payload: {
          distribution: { ...distribution, ...params }
        }
      });

      dispatch(finishRequest());
    } catch (error) {
      dispatch({ type: UPDATE_DRAFT_DISTRIBUTION_FAILURE });

      dispatch(failureRequest());

      throw error;
    }
  };
}

export function updateAndSend(distribution, params) {
  return async (dispatch) => {
    try {
      dispatch(startRequest());

      dispatch({ type: UPDATE_AND_SEND_DRAFT_DISTRIBUTION_REQUEST });

      const { id: distributionId } = distribution;
      await service.updateAndSend(distributionId, params);

      dispatch({
        type: UPDATE_AND_SEND_DRAFT_DISTRIBUTION_SUCCESS,
        payload: {
          distribution: { ...distribution, ...params }
        }
      });

      dispatch(finishRequest());
    } catch (error) {
      dispatch({ type: UPDATE_AND_SEND_DRAFT_DISTRIBUTION_FAILURE });

      dispatch(failureRequest());

      throw error;
    }
  };
}

export function addItem(distribution, combine) {
  return async (dispatch) => {
    try {
      dispatch(startRequest());

      dispatch({ type: ADD_ITEM_DISTRIBUTION_REQUEST });

      const { id: combineId } = combine;
      const { id: distributionId } = distribution;
      await service.addItem(distributionId, combineId);

      dispatch({ type: ADD_ITEM_DISTRIBUTION_SUCCESS });

      dispatch(fetchItems(1, { distribution: distributionId }));
    } catch (error) {
      dispatch({ type: ADD_ITEM_DISTRIBUTION_FAILURE });

      dispatch(failureRequest());

      throw error;
    }
  };
}

export function removeItem(item) {
  return async (dispatch) => {
    try {
      dispatch(startRequest());

      dispatch({ type: REMOVE_ITEM_DISTRIBUTION_REQUEST });

      const { id: itemId, distributionId } = item;
      await service.removeItem(itemId);

      dispatch({ type: REMOVE_ITEM_DISTRIBUTION_SUCCESS });

      dispatch(fetchItems(1, { distribution: distributionId }));
    } catch (error) {
      dispatch({ type: REMOVE_ITEM_DISTRIBUTION_FAILURE });

      dispatch(failureRequest());

      throw error;
    }
  };
}

export function showDetail(distribution) {
  return (dispatch) => {
    try {
      dispatch(startRequest());

      dispatch({ type: SHOW_DISTRIBUTION_DETAIL_SUCCESS, payload: { distribution } });

      dispatch(finishRequest());
    } catch (error) {
      dispatch(failureRequest());

      throw error;
    }
  };
}

export function cancel(distribution) {
  return async (dispatch, getState) => {
    try {
      const { distributions: oldDistributions } = getState().distribution;

      dispatch(startRequest());

      dispatch({ type: CANCEL_DRAFT_DISTRIBUTION_REQUEST });

      await service.cancel(distribution.id);

      const distributions = [...oldDistributions].map((item) => ({
        ...item,
        status: item.id === distribution.id ? 'CANCELED' : item.status
      }));

      dispatch({
        type: CANCEL_DRAFT_DISTRIBUTION_SUCCESS,
        payload: { distributions, distribution: { ...distribution, status: 'CANCELED' } }
      });

      dispatch(finishRequest());
    } catch (error) {
      dispatch({ type: CANCEL_DRAFT_DISTRIBUTION_FAILURE });

      dispatch(failureRequest());

      throw error;
    }
  };
}
