/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  FILTER_ORDER_FAILURE,
  FILTER_ORDER_SUCCESS,
  FILTER_ORDER_REQUEST,
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAILURE,
  FETCH_ORDER_ITEMS_REQUEST,
  FETCH_ORDER_ITEMS_SUCCESS,
  FETCH_ORDER_ITEMS_FAILURE,
  CANCEL_ORDER_FAILURE,
  CANCEL_ORDER_SUCCESS,
  CANCEL_ORDER_REQUEST,
  UPLOAD_REPORT_ORDER_REQUEST,
  UPLOAD_REPORT_ORDER_SUCCESS,
  UPLOAD_REPORT_ORDER_FAILURE
} from 'actions/orderActions';

const initialState = {
  orders: [],
  meta: {},
  filters: {},
  status: null,
  items: []
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case FILTER_ORDER_REQUEST: {
      const { filters } = action.payload;

      return produce(state, (draft) => {
        draft.filters = filters;
        draft.status = FILTER_ORDER_REQUEST;
      });
    }

    case FILTER_ORDER_SUCCESS: {
      const { orders, meta } = action.payload;

      return produce(state, (draft) => {
        draft.orders = orders;
        draft.meta = meta;
        draft.status = FILTER_ORDER_SUCCESS;
      });
    }

    case FILTER_ORDER_FAILURE: {
      const { filters } = action.payload;

      return produce(state, (draft) => {
        draft.filters = filters;
        draft.status = FILTER_ORDER_FAILURE;
      });
    }

    case CREATE_ORDER_REQUEST: {
      return produce(state, (draft) => {
        draft.status = CREATE_ORDER_REQUEST;
      });
    }

    case CREATE_ORDER_SUCCESS: {
      return produce(state, (draft) => {
        draft.status = CREATE_ORDER_SUCCESS;
      });
    }

    case CREATE_ORDER_FAILURE: {
      return produce(state, (draft) => {
        draft.status = CREATE_ORDER_FAILURE;
      });
    }

    case FETCH_ORDER_ITEMS_REQUEST: {
      return produce(state, (draft) => {
        draft.status = FETCH_ORDER_ITEMS_REQUEST;
      });
    }

    case FETCH_ORDER_ITEMS_SUCCESS: {
      const { items } = action.payload;

      return produce(state, (draft) => {
        draft.items = items;
        draft.status = FETCH_ORDER_ITEMS_SUCCESS;
      });
    }

    case FETCH_ORDER_ITEMS_FAILURE: {
      return produce(state, (draft) => {
        draft.status = FETCH_ORDER_ITEMS_FAILURE;
      });
    }

    case CANCEL_ORDER_REQUEST: {
      return produce(state, (draft) => {
        draft.status = CANCEL_ORDER_REQUEST;
      });
    }

    case CANCEL_ORDER_SUCCESS: {
      const { orderId } = action.payload;

      return produce(state, (draft) => {
        draft.orders = [...state.orders].map((order) => (orderId === order.id ? { ...order, status: 'CANCELED' } : order));

        draft.status = CANCEL_ORDER_SUCCESS;
      });
    }

    case CANCEL_ORDER_FAILURE: {
      return produce(state, (draft) => {
        draft.status = CANCEL_ORDER_FAILURE;
      });
    }
    case UPLOAD_REPORT_ORDER_REQUEST: {
      return produce(state, (draft) => {
        draft.status = UPLOAD_REPORT_ORDER_REQUEST;
      });
    }

    case UPLOAD_REPORT_ORDER_SUCCESS: {
      return produce(state, (draft) => {
        draft.status = UPLOAD_REPORT_ORDER_SUCCESS;
      });
    }

    case UPLOAD_REPORT_ORDER_FAILURE: {
      return produce(state, (draft) => {
        draft.status = UPLOAD_REPORT_ORDER_FAILURE;
      });
    }

    default: {
      return state;
    }
  }
};

export default orderReducer;
