/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  FETCH_ALL_PRODUCT_REQUEST,
  FETCH_ALL_PRODUCT_SUCCESS,
  FETCH_ALL_PRODUCT_FAILURE
} from 'actions/productActions';

const initialState = {
  products: [],
  meta: {},
  filters: {},
  status: null
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_PRODUCT_REQUEST: {
      return produce(state, (draft) => {
        draft.status = FETCH_ALL_PRODUCT_REQUEST;
      });
    }

    case FETCH_ALL_PRODUCT_SUCCESS: {
      const { products } = action.payload;

      return produce(state, (draft) => {
        draft.products = products;
        draft.status = FETCH_ALL_PRODUCT_SUCCESS;
      });
    }

    case FETCH_ALL_PRODUCT_FAILURE: {
      return produce(state, (draft) => {
        draft.status = FETCH_ALL_PRODUCT_FAILURE;
      });
    }

    default: {
      return state;
    }
  }
};

export default productReducer;
