import employeeService from 'services/employeeService';

export const FETCH_ALL_EMPLOYEE_REQUEST = '@employee/fetch-all-employee-request';
export const FETCH_ALL_EMPLOYEE_SUCCESS = '@employee/fetch-all-employee-success';
export const FETCH_ALL_EMPLOYEE_FAILURE = '@employee/fetch-all-employee-failure';

export function fetchAll() {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_ALL_EMPLOYEE_REQUEST });

      const { data: employees } = await employeeService.fetchAll();

      dispatch({ type: FETCH_ALL_EMPLOYEE_SUCCESS, payload: { employees } });
    } catch (error) {
      dispatch({ type: FETCH_ALL_EMPLOYEE_FAILURE });
      throw error;
    }
  };
}
