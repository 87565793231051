import axios from 'utils/axios';

class BranchService {
  fetchAll = () => new Promise((resolve, reject) => {
    axios.get('/branch?page=1&limit=100')
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  })
}

const branchService = new BranchService();

export default branchService;
