/* eslint-disable import/extensions */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-param-reassign */
import axios from 'axios';
import authService from 'services/authService';

import { API_URL } from 'config';
import humps from './humps';

const instance = axios.create({
  baseURL: API_URL,
  transformResponse: [
    ...axios.defaults.transformResponse,
    (data) => humps.camelizeKeys(data)
  ],
  transformRequest: [
    (data) => humps.decamelizeKeys(data),
    ...axios.defaults.transformRequest
  ]
});

instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
instance.defaults.headers.common.Accept = 'application/json';

instance.interceptors.request.use((config) => {
  if (authService.isAuthenticated()) {
    const token = authService.getAccessToken();

    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
},
(error) => Promise.reject(error));

instance.interceptors.response.use((response) => response, (error) => {
  if (axios.isCancel(error)) {
    return Promise.reject(error);
  }

  if (!error.response) {
    return Promise.reject(error);
  }

  if (!error.hasOwnProperty('response')) {
    return Promise.reject(error);
  }

  if (error.response.status === 401) {
    localStorage.clear();
  }

  return Promise.reject(error);
});

export default instance;
