import { combineReducers } from 'redux';
import accountReducer from './accountReducer';
import orderReducer from './orderReducer';
import donorReducer from './donorReducer';
import productReducer from './productReducer';
import pageReducer from './pageReducer';
import partnerReducer from './partnertReducer';
import orderItemReducer from './orderItemReducer';
import branchReducer from './branchReducer';
import employeeReducer from './employeeReducer';
import mergeReducer from './mergeReducer';
import regionReducer from './regionReducer';
import distributionReducer from './distributionReducer';
import orderReportReducer from './orderReportReducer';

const rootReducer = combineReducers({
  account: accountReducer,
  donor: donorReducer,
  order: orderReducer,
  product: productReducer,
  page: pageReducer,
  partner: partnerReducer,
  orderItem: orderItemReducer,
  branch: branchReducer,
  employee: employeeReducer,
  merge: mergeReducer,
  region: regionReducer,
  distribution: distributionReducer,
  orderReport: orderReportReducer
});

export default rootReducer;
