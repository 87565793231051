/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  FILTER_ORDER_REPORT_FAILURE,
  FILTER_ORDER_REPORT_SUCCESS,
  FILTER_ORDER_REPORT_REQUEST,
  SENDING_ORDER_REPORT_REQUEST,
  SENDING_ORDER_REPORT_SUCCESS,
  SENDING_ORDER_REPORT_FAILURE
} from 'actions/orderReportActions';

const initialState = {
  reports: [],
  meta: {},
  filters: {},
  status: null,
};

const orderReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case FILTER_ORDER_REPORT_REQUEST: {
      const { filters } = action.payload;

      return produce(state, (draft) => {
        draft.filters = filters;
        draft.status = FILTER_ORDER_REPORT_REQUEST;
      });
    }

    case FILTER_ORDER_REPORT_SUCCESS: {
      const { reports, meta } = action.payload;

      return produce(state, (draft) => {
        draft.reports = reports;
        draft.meta = meta;
        draft.status = FILTER_ORDER_REPORT_SUCCESS;
      });
    }

    case FILTER_ORDER_REPORT_FAILURE: {
      const { filters } = action.payload;

      return produce(state, (draft) => {
        draft.filters = filters;
        draft.status = FILTER_ORDER_REPORT_FAILURE;
      });
    }

    case SENDING_ORDER_REPORT_REQUEST: {
      return produce(state, (draft) => {
        draft.status = SENDING_ORDER_REPORT_REQUEST;
      });
    }

    case SENDING_ORDER_REPORT_SUCCESS: {
      const { reports } = action.payload;

      return produce(state, (draft) => {
        draft.reports = reports;
        draft.status = SENDING_ORDER_REPORT_SUCCESS;
      });
    }

    case SENDING_ORDER_REPORT_FAILURE: {
      return produce(state, (draft) => {
        draft.status = SENDING_ORDER_REPORT_FAILURE;
      });
    }

    default: {
      return state;
    }
  }
};

export default orderReportReducer;
