import productService from 'services/productService';

export const FETCH_ALL_PRODUCT_REQUEST = '@product/fetch-all-product-request';
export const FETCH_ALL_PRODUCT_SUCCESS = '@product/fetch-all-product-success';
export const FETCH_ALL_PRODUCT_FAILURE = '@product/fetch-all-product-failure';

export function fetchAll() {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_ALL_PRODUCT_REQUEST });

      const { data: products } = await productService.fetchAll();

      dispatch({ type: FETCH_ALL_PRODUCT_SUCCESS, payload: { products } });
    } catch (error) {
      dispatch({ type: FETCH_ALL_PRODUCT_FAILURE });
      throw error;
    }
  };
}

export function fetchActive() {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_ALL_PRODUCT_REQUEST });

      const { data: products } = await productService.fetchActive();

      dispatch({ type: FETCH_ALL_PRODUCT_SUCCESS, payload: { products } });
    } catch (error) {
      dispatch({ type: FETCH_ALL_PRODUCT_FAILURE });
      throw error;
    }
  };
}

export function fetchAllNonMergeable() {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_ALL_PRODUCT_REQUEST });

      const { data: products } = await productService.fetchAllNonMergeable();

      dispatch({ type: FETCH_ALL_PRODUCT_SUCCESS, payload: { products } });
    } catch (error) {
      dispatch({ type: FETCH_ALL_PRODUCT_FAILURE });
      throw error;
    }
  };
}
