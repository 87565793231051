import axios from 'utils/axios';
import filterFromObject from 'utils/filterFromObject';

class OrderService {
  fetchListOrder = (page = 1, filters = {}) =>
    new Promise((resolve, reject) => {
      const filterParam = filterFromObject(filters);

      axios
        .get(`/order?page=${page}&limit=10&${filterParam}`)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });

  fetchOrderItems = (id) =>
    new Promise((resolve, reject) => {
      axios
        .get(`/order/${id}`)
        .then((response) => resolve(response.data.details))
        .catch((error) => reject(error));
    });

  createOrder = (order) =>
    new Promise((resolve, reject) => {
      axios
        .post('/order', order)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });

  cancelOrder = (orderId) =>
    new Promise((resolve, reject) => {
      axios
        .put(`/order/${orderId}/cancel`)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });

  uploadReport = (orderId, formData) =>
    new Promise((resolve, reject) => {
      axios
        .post(`/order/${orderId}/report`, formData)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });

  fetchListOrderReport = (page = 1, filters = {}) =>
    new Promise((resolve, reject) => {
      const filterParam = filterFromObject(filters);

      axios
        .get(`/order/report?page=${page}&limit=10&${filterParam}`)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });

  downloadReport = (assetUrl, fileName) =>
    new Promise((resolve, reject) => {
      fetch(assetUrl, { method: 'GET' })
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Laporan Qurban ${fileName}`);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => reject(error));
    });

  sendingReport = (report, distributionAt) =>
    new Promise((resolve, reject) => {
      axios
        .post(`/order/report/${report.id}/send`, { distributionAt })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });

  sendingNotification = (orderId) =>
    new Promise((resolve, reject) => {
      axios
        .get(`/order/${orderId}/send`)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });

  generateTrackingUrl = (orderId) =>
    new Promise((resolve, reject) => {
      axios
        .get(`/order/${orderId}/tracking`)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
}

const orderService = new OrderService();

export default orderService;
