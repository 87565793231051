/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  FETCH_ALL_EMPLOYEE_REQUEST,
  FETCH_ALL_EMPLOYEE_SUCCESS,
  FETCH_ALL_EMPLOYEE_FAILURE
} from 'actions/employeeActions';

const initialState = {
  employees: [],
  meta: {},
  filters: {},
  status: null
};

const employeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_EMPLOYEE_REQUEST: {
      return produce(state, (draft) => {
        draft.status = FETCH_ALL_EMPLOYEE_REQUEST;
      });
    }

    case FETCH_ALL_EMPLOYEE_SUCCESS: {
      const { employees } = action.payload;

      return produce(state, (draft) => {
        draft.employees = employees;
        draft.status = FETCH_ALL_EMPLOYEE_SUCCESS;
      });
    }

    case FETCH_ALL_EMPLOYEE_FAILURE: {
      return produce(state, (draft) => {
        draft.status = FETCH_ALL_EMPLOYEE_FAILURE;
      });
    }

    default: {
      return state;
    }
  }
};

export default employeeReducer;
