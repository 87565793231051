import axios from 'utils/axios';
import filterFromObject from 'utils/filterFromObject';

class OrderItemService {
  fetchListItem = (page = 1, filters = {}, perPage = 10) => new Promise((resolve, reject) => {
    const filterParam = filterFromObject(filters);

    axios.get(`/order/item?page=${page}&limit=${perPage}&${filterParam}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  })

  updateNames = (itemId, newNames) => new Promise((resolve, reject) => {
    axios.patch(`/order/item/${itemId}/name`, { names: newNames })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  })

  transferBranch = (itemId, newBranchId) => new Promise((resolve, reject) => {
    axios.patch(`/order/item/${itemId}/transfer`, { processBranchId: newBranchId })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  })

  mergeItems = (params) => new Promise((resolve, reject) => {
    axios.post('/merge', { ...params })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  })

  fetchListMergeItems = (page = 1, filters = {}, perPage = 10) => new Promise((resolve, reject) => {
    const filterParam = filterFromObject(filters);

    axios.get(`/merge?page=${page}&limit=${perPage}&${filterParam}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  })

  fetchDetailMergeItem = (mergeId) => new Promise((resolve, reject) => {
    axios.get(`/merge/${mergeId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  })

  cancelMergeItem = (itemId) => new Promise((resolve, reject) => {
    axios.put(`/merge/${itemId}/cancel`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  })

  cutedMergeItem = (itemId, params) => new Promise((resolve, reject) => {
    axios.put(`/merge/${itemId}/cuted`, { ...params })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  })
}

const orderItemService = new OrderItemService();

export default orderItemService;
