import service from 'services/partnerService';

export const FETCH_ALL_PARTNER_REQUEST = '@partner/fetch-all-partner-request';
export const FETCH_ALL_PARTNER_SUCCESS = '@partner/fetch-all-partner-success';
export const FETCH_ALL_PARTNER_FAILURE = '@partner/fetch-all-partner-failure';

export function fetchAll() {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_ALL_PARTNER_REQUEST });

      const { data: partners } = await service.fetchAll();

      dispatch({ type: FETCH_ALL_PARTNER_SUCCESS, payload: { partners } });
    } catch (error) {
      dispatch({ type: FETCH_ALL_PARTNER_FAILURE });

      throw error;
    }
  };
}
