import axios from 'utils/axios';
import filterFromObject from 'utils/filterFromObject';

class ProductService {
  fetchAll = () =>
    new Promise((resolve, reject) => {
      axios
        .get('/product?page=1&limit=100')
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });

  fetchActive = () =>
    new Promise((resolve, reject) => {
      axios
        .get('/product?page=1&limit=100&filter[is_active]=true')
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });

  fetchListProduct = (page = 1, filters = {}) =>
    new Promise((resolve, reject) => {
      const filterParam = filterFromObject(filters);

      axios
        .get(`/product?page=${page}&limit=10&${filterParam}`)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });

  fetchAllNonMergeable = () =>
    new Promise((resolve, reject) => {
      axios
        .get(
          '/product?page=1&limit=100&filter[selected_merge]=true&filter[mergeable]=false&filter[is_active]=true'
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
}

const productService = new ProductService();

export default productService;
