/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  FETCH_ALL_PARTNER_REQUEST,
  FETCH_ALL_PARTNER_SUCCESS,
  FETCH_ALL_PARTNER_FAILURE
} from 'actions/partnerActions';

const initialState = {
  partners: [],
  status: null
};

const partnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_PARTNER_REQUEST: {
      return produce(state, (draft) => {
        draft.status = FETCH_ALL_PARTNER_REQUEST;
      });
    }

    case FETCH_ALL_PARTNER_SUCCESS: {
      const { partners } = action.payload;

      return produce(state, (draft) => {
        draft.partners = partners;
        draft.status = FETCH_ALL_PARTNER_SUCCESS;
      });
    }

    case FETCH_ALL_PARTNER_FAILURE: {
      return produce(state, (draft) => {
        draft.status = FETCH_ALL_PARTNER_FAILURE;
      });
    }

    default: {
      return state;
    }
  }
};

export default partnerReducer;
