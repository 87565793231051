/* eslint-disable no-param-reassign */
import produce from 'immer';
import { PAGE_REQUEST_START, PAGE_REQUEST_FINISH, PAGE_REQUEST_FAILURE } from 'actions/pageActions';

const initialState = {
  status: null,
  message: null
};

const pageReducer = (state = initialState, action) => {
  switch (action.type) {
    case PAGE_REQUEST_START: {
      return produce(state, (draft) => {
        draft.status = PAGE_REQUEST_START;
      });
    }

    case PAGE_REQUEST_FINISH: {
      const { message } = action.payload;

      return produce(state, (draft) => {
        draft.status = PAGE_REQUEST_FINISH;
        draft.message = message;
      });
    }

    case PAGE_REQUEST_FAILURE: {
      const { message } = action.payload;

      return produce(state, (draft) => {
        draft.status = PAGE_REQUEST_FAILURE;
        draft.message = message;
      });
    }

    default: {
      return state;
    }
  }
};

export default pageReducer;
