import axios from 'utils/axios';
import filterFromObject from 'utils/filterFromObject';

class DonorService {
  fetchListDonor = (page = 1, filters = {}) => new Promise((resolve, reject) => {
    const filterParam = filterFromObject(filters);

    axios.get(`/donor?page=${page}&limit=10&${filterParam}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  })
}

const donorService = new DonorService();

export default donorService;
